/* animation css start */
@import './component/asset/css/animation.css';




#my_form2 {
   min-height:450px ;
}
#my_form {
    min-height:515px ;
 }









  
