.featured-section {
	position: relative;
	padding: 50px 0px 90px;
	.icon-one {
		position: absolute;
		left: 20%;
		top: 12%;
		width: 132px;
		height: 132px;
		background-repeat: no-repeat;
	}
	.icon-two {
		position: absolute;
		left: 0%;
		bottom: 0%;
		width: 255px;
		height: 360px;
		background-repeat: no-repeat;
	}
}
.feature-block {
	position: relative;
	margin-bottom: 30px;
	.inner-box {
		position: relative;
		overflow: hidden;
		padding: 55px 15px 50px;
		text-align: center;
		background-color: #e9f6f06b;
		.overlay-link {
			position: absolute;
			left: 0px;
			top: 0px;
			right: 0px;
			bottom: 0px;
			display: block;
			z-index: 1;
		}
		&:before{
			position: absolute;
			content: '';
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			color: #ffffff;
			text-align: center;
			background-image: -ms-linear-gradient(to right, #86bc24 0%, #86bc24 100%);
			background-image: -moz-linear-gradient(to right, #86bc24 0%, #86bc24 100%);
			background-image: -o-linear-gradient(to right, #86bc24 0%, #86bc24 100%);
			background-image: -webkit-gradient(to right, #86bc24 0%, #86bc24 100%);
			background-image: -webkit-linear-gradient(to right, #86bc24 0%, #86bc24 100%);
			background-image: linear-gradient(to right, #86bc24 0%, #86bc24 100%);
			transition: -webkit-transform 0.4s ease;
			transition: transform 0.4s ease;
			transition: transform 0.4s ease, -webkit-transform 0.4s ease;
			-webkit-transform: scale(0, 1);
			-ms-transform: scale(0, 1);
			transform: scale(0, 1);
			-webkit-transform-origin: right center;
			-ms-transform-origin: right center;
			transform-origin: right center;
			
		}
		&:hover {
			&::before {
				-webkit-transform: scale(1, 1);
				-ms-transform: scale(1, 1);
				transform: scale(1, 1);
				-webkit-transform-origin: left center;
				-ms-transform-origin: left center;
				transform-origin: left center;
			}
			.icon-box {
				&:before {
					background-color: rgba(255,255,255,0.10);
				}
				.icon.on-hover {
					display: block;
				}
				.icon.off-hover {
					display: none;
				}
				.icon {
					color: #ffffff;
				}
				-webkit-transform: scale(-1) rotate(180deg);
				-moz-transform: scale(-1) rotate(180deg);
				-ms-transform: scale(-1) rotate(180deg);
				-o-transform: scale(-1) rotate(180deg);
				transform: scale(-1) rotate(180deg);
			}
			h5 {
				color: #ffffff;
			}
		}
		.icon-box {
			position: relative;
			display: inline-block;
			transition: all 600ms ease;
			-moz-transition: all 600ms ease;
			-webkit-transition: all 600ms ease;
			-ms-transition: all 600ms ease;
			-o-transition: all 600ms ease;
			&:before {
				position: absolute;
				content: '';
				left: -15px;
				top: 0px;
				width: 35px;
				height: 35px;
				border-radius: 50px;
				background-color: rgba(241, 250, 225, 0.2);
			}
			.icon {
				position: relative;
				font-size: 60px;
				line-height: 1em;
				color: #61dafb;
				transition: all 300ms ease;
				-webkit-transition: all 300ms ease;
				-ms-transition: all 300ms ease;
				-o-transition: all 300ms ease;
				-moz-transition: all 300ms ease;
				img {
					width: 60px;
				}
			}
			.icon.axios {
				img {
					width: auto;
				}
			}
			.icon.on-hover {
				display: none;
			}
		}
		h5 {
			position: relative;
			font-weight: 500;
			color: #06092d;
			font-size: 18px;
			line-height: 1.3em;
			margin-top: 18px;
			transition: all 300ms ease;
			-webkit-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
		}
	}
	.inner-box.inner-box-two{
		-webkit-transform-origin: top center;
		-ms-transform-origin: top center;
		transform-origin: top center;
		&:hover {
			&::before {				
				-webkit-transform-origin: top center;
				-ms-transform-origin: top center;
				transform-origin: top center;
			}
		}
	}

	.inner-box.inner-box-three{
		-webkit-transform-origin: left center;
		-ms-transform-origin: left center;
		transform-origin: left center;
		&:hover {
			&::before {				
				-webkit-transform-origin: right center;
				-ms-transform-origin: right center;
				transform-origin: right center;
			}
		}
	}
	.inner-box.inner-box-four{
		transition: all 0.8s;
	
		&::before {				
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			transition: all 0.8s;
			transform: rotateZ(360deg);
			opacity: 0;
	
}
		&:hover {
			&::before {				
				transform: rotateZ(
					-360deg);
					opacity: 1;

			}
		}
	}

	.inner-box.inner-box-five{
		transition: all 0.8s;
		transform: scale(1);
		overflow:inherit;
	
		&::before {				
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			transition: all 0.8s;
			transform: rotateZ(-10deg);
			opacity: 0;
			
	
}
		&:hover {
			&::before {				
				transform: rotateZ(
					10deg);
					opacity: 1;

			}
		transform: scale(0.8);

		}
	}

	.inner-box.inner-box-six{
		transition: all 0.8s;
		transform: translateY(0px);
	
		&::before {				
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			transition: all 0.8s;
			transform: translateY(10px);
			opacity: 0;
			
	
}
		&:hover {
			&::before {				
				transform: translateY(-10px);
					opacity: 1;

			}
			transform: translateY(-10px);
			

		}
	}

	.inner-box.inner-box-seven{
		transition: all 0.8s;
		transform: scale(1);
		overflow:inherit;
	
		&::before {				
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			transition: all 0.8s;
			transform: rotateZ(10deg);
			opacity: 0;
			
	
}
		&:hover {
			&::before {				
				transform: rotateZ(
					-10deg);
					opacity: 1;

			}
			transform: scale(0.8);


		}
	}

	.inner-box.inner-box-eight{
		transition: all 0.8s;
		transform: translateY(0px);
	
		&::before {				
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			transition: all 0.8s;
			transform: translateY(0px);
			opacity: 0;
			
	
}
		&:hover {
			&::before {				
				transform: translateY(10px);
					opacity: 1;

			}
			transform: translateY(10px);
			

		}
	}


}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
	.fantasy_area.process_service {
		.devopment_img {
			margin-left: 0rem;
			opacity: 0.8;
		}
	}
}
