.axil-footer {
	overflow: hidden;
	position: relative;
	.color-layer {
		position: absolute;
		left: -10%;
		bottom: -70%;
		width: 465px;
		height: 465px;
		border-radius: 50%;
		background-color: #86bc24;
		opacity: 0.1;
	}
	.icon-layer-one {
		position: absolute;
		left: 15%;
		bottom: 100px;
		width: 160px;
		height: 133px;
		background-repeat: no-repeat;
	}
	.icon-layer-two {
		position: absolute;
		right: 5%;
		top: 10%;
		width: 69px;
		height: 69px;
		background-repeat: no-repeat;
	}
	.icon-layer-three {
		position: absolute;
		right: 6%;
		bottom: 120px;
		width: 69px;
		height: 69px;
		opacity: 0.5;
		background-repeat: no-repeat;
	}
}
.bg_image--2 {
	background-repeat: no-repeat;
	background-size: inherit;
	background-position: top right;
	background: #fff url(../images/background/bg-image-2.svg);
}
.ax-section-gapTop {
	padding-top: 50px;
}
.footer-default {
	.footer-widget-item {
		h2 {
			margin-bottom: 10px;
			display: block;
			font-size: 45px;
			color: #06092d;
			font-weight: 700;
			transition: all 0.5s;
		}
		p {
			margin: 30px 0px;
			font-size: 16px;
			line-height: 28px;
			color: #757589;
		}
	}
}
.footer-widget-item {
	text-align: center;
}
.axil-basic-thine-line {
	border-top: 1px solid #C7C7D5;
}
.ptb--20 {
	padding: 20px 0;
}
.social-box {
	position: relative;
	text-align: center;
	margin-bottom: 2rem;
	li {
		position: relative;
		margin-right: 8px;
		display: inline-block;
		a {
			position: relative;
			color: #000;
			font-size: 15px;
			width: 34px;
			height: 34px;
			line-height: 34px;
			text-align: center;
			border-radius: 50px;
			border: 1px solid #000;
			display: inline-block;
			background-color: transparent;
			transition-duration: 0.5s;
			&:hover {
				color: #fff;
				border-color: transparent;
				transform: scale(1.2);
				transition-duration: 0.5s;
				&:before {
					transform: rotateY(360deg);
				}
			}
			&:before {
				-webkit-transition: all 600ms ease;
				-ms-transition: all 600ms ease;
				-o-transition: all 600ms ease;
				-moz-transition: all 600ms ease;
				transition: all 600ms ease;
				display: inline-block;
			}
		}
	}
	li.twitter {
		&:hover {
			a {
				background-color: #55acee;
			}
		}
	}
	li.facebook {
		&:hover {
			a {
				background-color: #3b5999;
			}
		}
	}
	li.linkedin {
		&:hover {
			a {
				background-color: #0e76a8;
			}
		}
	}
	li.skype {
		&:hover {
			a {
				background-color: #00aff0;
			}
		}
	}
	li.instagram {
		&:hover {
			a {
				background: #f09433;
				background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
				background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
				background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
			}
		}
	}
}
.copyright-default {
	.inner {
		p {
			font-size: 14px;
			margin: 0px;
		}
	}
	.quick-contact {
		ul {
			li {
				margin: 0 15px;
				a {
					text-decoration: none;
					font-size: 14px;
				}
			}
		}
	}
}

.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 40px;
	height: 40px;
	color: #ffffff !important;
	font-size: 13px;
	text-transform: uppercase;
	line-height: 38px;
	text-align: center;
	z-index: 100;
	cursor: pointer;
	background: #222222;
	display: block;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	&:hover {
		color: #ffffff;
		background: #000000;
	}
}

.contact-section.side_fix_form {
    max-width:340px;
	width: 100%;
    margin: 0 auto;
    overflow: initial;
    position: fixed;
    right: -340px;
    top: 50%;
    z-index: 11;
	transform: translateY(-65%);
	bottom: 0%;
	transition: 0.5s;
	.btns-box{
			transform: rotate(90deg);
			position: absolute;
			left: -114px;
			top: 50%;
			.btn-style-one {
				font-size: 14px;
				line-height: 30px;
				padding: 8px 20px;		
			.txt{
				&:before{
					display: none;
				}
				&:after{
					display: none;
				}
			}

			}
	}
	.form-column{
		.inner-column{
			padding: 10px;
			margin:0px;
			background: white url(../images/background/pattern-9.png) no-repeat bottom right;
			background-position: top;
			background-size: contain;
			.sec-title  {
				margin-bottom: 0px;
				h2{
				position: relative;
				color: #06092d;
				font-size: 20px;
				line-height: 25px;
padding-left: 35px;
				margin:0px;
				font-weight: 500;
				transform-origin: top;
				transition: all 1000ms ease;
			}
		}
		
		}

	}
}
.contact-section.side_fix_form.form_slide{
right:0px;
transition: 0.5s;
}
@media only screen and (min-width: 320px) and (max-width: 575px) {
	.contact-section.side_fix_form {
	max-width:320px;
    right: -320px;
	.form-column {
		.inner-column {
			.sec-title {
				h2{
					font-size:18px;
				}
			}
		}
	}
	
	}



}


