.fa_3 {
	margin-top: -20px;
}
.fa_2 {
	padding-top: 250px;
	padding-bottom: 0;
	.fantasy_inner {
		.fantasy_4 {
			.icons {
				color: #b587fb;
			}
		}
		.fantasy {
			.icons {
				background: #fff !important;
				color: #8a7bfe;
				box-shadow: 0px 27px 18px 0px rgba(164, 109, 253,  35%);
			}
			p {
				padding-bottom: 0;
			}
		}
	}
}
.fantasy_area {
	display: block;
	overflow: hidden;
	background: url(../images/background/pattern-8.png) no-repeat top right;
	padding: 80px 0px;
	margin-top: -130px;
	position: relative;
	.fantasy_inner {
		.fantasy {
			box-shadow: 0px 0px 19px 0px rgba(215, 215, 215,  0.2);
			border-radius: 0px 15px 0px 15px;
			margin-bottom: 0px;
			padding: 30px 35px 65px 65px;
			background: white url(../images/background/pattern-9.png) no-repeat bottom right;
			background-repeat: repeat;
			background-size: cover;
			position: relative;
			z-index: 1;
			transition: all 1s;
			.icons {
				width: 85px;
				line-height: 85px;
				font-size: 40px;
				color: #06092d;
				text-align: center;
				display: block;
				box-shadow: 0px 27px 18px 0px rgba(134, 188, 36,  0.2);
				border-radius: 10px;
				position: relative;
				i {
					transition: all 500ms ease;
					-moz-transition: all 500ms ease;
					-webkit-transition: all 500ms ease;
					-ms-transition: all 500ms ease;
					-o-transition: all 500ms ease;
				}
				&:after {
					content: "";
					position: absolute;
					box-shadow: 0px 18px 27px 0px rgba(203, 203, 203,  0.2);
					background: #eaddff;
					border-radius: 50%;
					height: 60px;
					width: 60px;
					z-index: -1;
					left: -24px;
					bottom: -20px;
					-webkit-animation: moveRound 30s linear 1s infinite alternate;
					animation: moveRound 30s linear 1s infinite alternate;
				}
			}
			&:hover {
				i {
					-webkit-transform: scale(-1) rotate(180deg);
					-moz-transform: scale(-1) rotate(180deg);
					-ms-transform: scale(-1) rotate(180deg);
					-o-transform: scale(-1) rotate(180deg);
					transform: scale(-1) rotate(180deg);
				}
			}
			a {
				&:hover {
					color: #86bc24;
				}
				&:focus {
					color: #86bc24;
				}
				display: block;
				font-size: 20px;
				color: #06092d;
				padding-top: 55px;
				font-weight: 500;
				transition: all 0.5s;
			}
			p {
				color: rgba(40, 35, 49, 0.8);
				font-size: 16px;
				padding: 35px 0;
			}
		}
	}
	.fantasy.fantasy_2 {
		margin-top: 5rem;
	}
	.fantasy.fantasy_4 {
		margin-top: 5rem;
	}
}
.fantasy_area.process_service {
	background: transparent;
	padding-top: 10rem;
	.fantasy_inner {
		.fantasy {
			text-align: center;
			padding: 20px;
			p {
				padding: 20px 0px;
				margin: 0px;
			}
			a {
				padding-top: 0px;
			}
		}
	}
	.devopment_img {
		margin-left: -15rem;
		opacity: 0.8;
	}
	.fantasy-circle-style {
		margin-bottom: 25px;
	}
}
.fantasy-circle-style.green-hover {
	.main-circle {
		img {
			height: 65px;
			margin-top: 5px;
		}
		i {
			color: #6cdac1;
			&:before {
				font-size: 40px;
				line-height: 80px;
			}
		}
	}
	.effect-circle {
		border: 1px solid rgba(100, 218, 193, 0.6);
		&:before {
			border-color: #6cdac1;
		}
	}
	.circle-bg {
		background: rgba(100, 218, 193, 0.2);
	}
}
.fantasy-circle-style {
	.circle-bg {
		width: 80px;
		text-align: center;
		position: relative;
		height: 80px;
		border-radius: 900px;
		i {
			font-size: 40px;
			line-height: 80px;
		}
	}
	.main-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
	}
	.effect-circle {
		width: 110px;
		height: 110px;
		border-radius: 900px;
		border: 1px solid rgba(255, 106, 92, 1);
		-webkit-animation: rotate-center 25s linear 100000;
		animation: rotate-center 25s linear 100000;
		position: absolute;
		&:before {
			width: 10px;
			height: 10px;
			top: -5px;
			background: #ffffff;
			border: 1px solid #6f73f0;
			border-radius: 90px;
			display: inline-block;
			position: absolute;
			content: "";
		}
	}
	.dot-circle {
		position: relative;
		width: 110px;
		height: 110px;
		display: inline-block;
		margin-bottom: 20px;
	}
}
.fantasy-circle-style.purple-hover {
	.effect-circle {
		border: 1px solid rgba(134, 188, 36, 0.2);
		&:before {
			border-color: #86bc24;
		}
	}
	.circle-bg {
		background: rgba(134, 188, 36, 0.2);
	}
	.main-circle {
		i {
			color: #86bc24;
			&:before {
				font-size: 40px;
				line-height: 80px;
			}
		}
	}
}


@-webkit-keyframes rotate-center {
	0% { -webkit-transform: rotate(0); transform: rotate(0); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes rotate-center {
	0% { -webkit-transform: rotate(0); transform: rotate(0); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-webkit-keyframes anti-clockwise {
	0% { -webkit-transform: rotate(0); transform: rotate(0); }
	100% { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}
@keyframes anti-clockwise {
	0% { -webkit-transform: rotate(0); transform: rotate(0); }
	100% { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}
@keyframes moveRound {
	0% {
	  background-color: #86bc249e;
	  left: -24px;
	  top: 0px;
	}
  
	25% {
	  background-color: #e9f6f0;
	  left: 100px;
	  top: 0px;
	}
  
	50% {
	  background-color: #efe4ff;
	  left: 100px;
	  top: 100px;
	}
  
	75% {
	  background-color: #e2fcf4;
	  left: -24px;
	  top: 200px;
	}
  
	100% {
	  background-color: #eee3ff;
	  left: -24px;
	  top: 0px;
	}
  }
  @-webkit-keyframes moveRound {
	0% {
	  background-color: #eee3ff;
	  left: -24px;
	  top: 0px;
	}
  
	25% {
	  background-color: #feddfc;
	  left: 100px;
	  top: 0px;
	}
  
	50% {
	  background-color: #efe4ff;
	  left: 100px;
	  top: 200px;
	}
  
	75% {
	  background-color: #e2fcf4;
	  left: -24px;
	  top: 100px;
	}
  
	100% {
	  background-color: #eee3ff;
	  left: -24px;
	  top: 0px;
	}
  }